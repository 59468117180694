import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-scroll";

// Components
import SocialIcons from "../SocialIcon/SocialIcons";
import FooterMenu, { Footer } from "./Footer";

// Data
import FooterData from "./FooterData";

/** Logo & images */
import LogoWhite from "../../../Assets/img/kalashproducts/icons/footer-logo.webp";
import TeckatLogo from "../../../Assets/img/kalashproducts/icons/logo.png";

const iconData = [
  {
    color: "#828282",
    link: "https://www.facebook.com/",
    icon: "fab fa-facebook-f",
  },
  // {
  //   color: "#828282",
  //   link: "https://dribbble.com/",
  //   icon: "fab fa-dribbble",
  // },
  {
    color: "#828282",
    link: "https://twitter.com/",
    icon: "fab fa-twitter",
  },
  {
    color: "#828282",
    link: "https://www.instagram.com/",
    icon: "fab fa-instagram",
  },
];

const FooterMain = () => {
  return (
    <div>
      <Footer theme="dark" className="bg-darkgray text-[#7e7e7e]">
        <div className="py-[5%] lg:py-[95px] md:py-[50px]">
          <Container>
            <Row>
              <FooterMenu
                data={FooterData.slice(0, 2)}
                lg={3}
                sm={6}
                className="xl:px-[15px] md:mb-[40px] xs:mb-[25px]"
                titleClass="capitalize"
              />
              <Col lg={3} sm={6} className="xs:mb-[25px]">
                <span className="mb-[20px] font-serif block font-medium text-themecolor xs:mb-[10px]">
                  Get in touch
                </span>
                <p className="w-[85%] mb-[15px]">
                  Main Road, Jugsalai, Beside Chhappan Bhog, Jamshedpur - 831006
                </p>
                <div>
                  <a href="tel:89692290677">
                    <i className="feather-phone-call text-sm mr-[10px] text-themecolor"></i>
                    8969-2290677
                  </a>
                </div>
                <div>
                  <i className="feather-mail text-sm mr-[10px] text-themecolor"></i>
                  <a aria-label="mail" href="mailTo:basukinath2010@gmail.com">
                    basukinath2010@gmail.com
                  </a>
                </div>
              </Col>
              <Col lg={3} sm={6}>
                {/* <span className="mb-[20px] font-medium font-serif text-themecolor block xs:mb-[20px]">
                  Follow us on Instagram
                </span> */}
                <div className="w-full inline-block">
                  {/* <StaticInstagram total_posts={3} /> */}
                  <div className="logo flex flex-col  justify-end items-center md:block">
                    <div>
                      <Link
                        aria-label="homepage"
                        to="/"
                        className="sm:flex sm:justify-center"
                      >
                        <img
                          alt="logo"
                          src={LogoWhite}
                          width="150"
                          // height="36"
                        />
                      </Link>
                    </div>

                    <div className="mt-14">
                      <SocialIcons
                        size="xs"
                        theme="social-icon-style-01"
                        className="justify-end sm:justify-center"
                        iconColor="white"
                        data={iconData}
                      />
                    </div>
                  </div>
                  {/* <a
                    aria-label="link"
                    rel="noreferrer"
                    href="https://www.instagram.com/"
                    target="_blank"
                    className="flex justify-start text-slateblue font-medium text-xs font-serif uppercase mt-[20px] items-center hover:text-white"
                  >
                    <i className="fab fa-instagram text-lg mr-[10px] text-gradient bg-gradient-to-r from-[#ff85a6] to-[#b783ff]"></i>
                    <span className="inline-block hover:text-white">
                      Follow instagram
                    </span>
                  </a> */}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="py-[40px] border-t border-[#ffffff1a]">
          <Container>
            <Row>
              {/* <Col md={3} className="sm:mb-[20px]">
                <Link
                  aria-label="homepage"
                  to="/"
                  className="sm:flex sm:justify-center"
                >
                  <img alt="logo" src={LogoWhite} width="111" height="36" />
                </Link>
              </Col> */}
              <Col
                md={12}
                className="flex justify-center items-center text-center sm:mb-[20px]"
              >
                <div className="footer_copyright text-center">
                  <div>
                    © 2023 kalashproducts.com | All Rights Reserved.
                    <div className="developed_By flex justify-center ">
                      Developed By
                      <a
                        aria-label="themezaa"
                        href="https://teckat.com/"
                        target="_blank"
                        rel="noreferrer"
                        className="flex underline underline-offset-4 text-[#fff] hover:text-[#1D2C5A] "
                      >
                        <img
                          src={TeckatLogo}
                          alt=""
                          className="w-auto h-8 mx-1"
                        />
                        Teckat.com
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
              {/* <Col md={3} className="text-right sm:text-center">
                <SocialIcons
                  size="xs"
                  theme="social-icon-style-01"
                  className="justify-end sm:justify-center"
                  iconColor="white"
                  data={iconData}
                />
              </Col> */}
            </Row>
          </Container>
        </div>
      </Footer>
    </div>
  );
};

export default FooterMain;
